@font-face {
    font-family: 'Inter';
    src: local('Inter Medium'), local('Inter-Medium'),
    url('./static/fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Medium'), local('Source-Code-Pro-Medium'),
    url('./static/fonts/SourceCodePro/SourceCodePro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}